import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/invoices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/invoice/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/listshop')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/listorder',  userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    listtransfer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/listtransfer' ,  userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listpayment(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/listpayment' ,  userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listpaymentexport(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/listpaymentexport' ,  userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listcustomer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/driverList' ,  userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    listroutemaster(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/routeList' ,  userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    listcarmaster(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/carList' ,  userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    stafflist(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/staffList' ,  userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getShop() {
      return new Promise((resolve, reject) => {
        axios
          .post('http://202.129.206.107:11009/listshop')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
